<template>
  <div class="widget-body" ref="widgetBody" v-if="ready">
    <section class="container px-0" ref="datePicker">
      <div class="row">
        <div class="col-12 col-lg-8 pb-3">
          <div v-on:click.stop="showDatepicker">
            <HotelDatePicker
              :custom-translations="customTranslations"
              :starting-date-value="
                payload.from_date ? new Date($moment(payload.from_date)) : null
              "
              :ending-date-value="
                payload.to_date ? new Date($moment(payload.to_date)) : null
              "
              :first-day-of-week="$i18n.locale === 'fr' ? 7 : 1"
              class="select-dates"
              :closeDatepickerOnClickOutside="false"
              @period-selected="periodSelected(...arguments)"
              :format="$t('datepicker.format')"
              ref="db"
              :class="{
                'is-invalid':
                  errors.indexOf('to_date') > -1 &&
                  errors.indexOf('from_date') > -1
              }"
            />
            <div class="invalid-feedback">
              {{
                $t("form.error.hint_missing", {
                  field: $t("form.check_in_out", {
                    check_in:
                      customTranslations.get("%label.form.arrival%") ||
                      $t("datepicker.check-in"),
                    check_out:
                      customTranslations.get("%label.form.departure%") ||
                      $t("datepicker.check-out")
                  })
                })
              }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="row room-wrapper"
        v-for="(room, index) in payload.products"
        :key="index + 'payload'"
      >
        <div v-if="showOffers">
          <div class="col-md-12 col-12 py-3">
            <label :for="'offer-select' + index" class="form-label">
              {{
                `${
                  customTranslations.get("%label.form.offer%") ||
                  $tc("form.offer.offer_pl", 1)
                } ${payload.products.length > 1 ? index + 1 : ""}`
              }}
            </label>
            <select
              :disabled="!showOffers"
              class="form-select form-control"
              :id="'offer-select' + index"
              v-model="payload.products[index].rateplan_code"
            >
              <option value="">
                {{
                  customTranslations.get("%label.form.any_offer%") ||
                  $t("form.offer.any")
                }}
              </option>
              <option
                :key="offerIndex"
                v-for="(offer, offerIndex) in availableOffers"
                :value="offer.external_code"
              >
                {{ offer.title }}
              </option>
            </select>
          </div>
        </div>
        <div
          v-if="showOffers"
          :set="
            (offerDetails = getOfferByExtCode(
              payload.products[index].rateplan_code
            ))
          "
        >
          <div
            v-if="offerDetails && payload.products[index].rateplan_code"
            :set="
              (mediaDetails = {
                mediaList: getMediaList(offerDetails),
                mainImage: getMainImage(offerDetails)
              })
            "
          >
            <div
              :set="
                (mediaCount =
                  (mediaDetails.mediaList.length || 0) +
                  (mediaDetails.mainImage ? 1 : 0))
              "
              class="mb-3 col-md-12 col-12 py-1"
            >
              <div class="row g-0">
                <div class="col-md-3" v-if="mediaDetails.mainImage">
                  <div
                    @click="
                      $refs.carouselModal.open(
                        getGalleryMediaByCode(
                          'offer',
                          payload.products[index].rateplan_code
                        )
                      )
                    "
                    class="picture-wrapper ratio ratio-4x3"
                    :style="{
                      'background-image':
                        'url(' + (mediaDetails.mainImage.url + '?d=400x300)')
                    }"
                  >
                    <div class="image-count">
                      {{ mediaCount }}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="white"
                        class="bi bi-image"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                        ></path>
                        <path
                          d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="col-md-9">
                  <div class="my-3 my-md-0 mx-0 mx-md-3">
                    <div class="row">
                      <div class="d-flex mb-2 offer-info-wrapper">
                        <div class="offer-title">{{ offerDetails.title }}</div>
                        <div v-if="offerDetails.periods">
                          <div
                            v-for="period in offerDetails.periods"
                            :key="`period_name_${period.name}`"
                            class="ms-2 offer-subtitle d-flex justify-content-end"
                          >
                            <span class="offer-title">&nbsp;</span>
                            <span
                              class="d-flex flex-column justify-content-end"
                            >
                              {{ getFormattedPeriod(period) }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p class="" v-html="offerDetails.description"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="col-md-12 col-12 py-3">
            <label :for="'room-select' + index" class="form-label">
              {{
                `${
                  customTranslations.get("%label.fields.room%") ||
                  $tc("form.room.room_pl", 1)
                } ${payload.products.length > 1 ? index + 1 : ""}`
              }}
            </label>
            <select
              :disabled="!showRooms"
              class="form-select form-control"
              :id="'room-select' + index"
              v-model="payload.products[index].product_code"
              @change="selectRoom(index, $event.target.value)"
            >
              <option value="">
                {{
                  customTranslations.get("%label.fields.room.placeholder%") ||
                  $t("form.room.any")
                }}
              </option>
              <option
                :key="roomIndex"
                v-for="(room, roomIndex) in availableRooms"
                :value="room.external_code"
              >
                {{ room.title }}
              </option>
            </select>
          </div>
        </div>
        <div
          :set="
            (roomDetails = getRoomByExtCode(
              payload.products[index].product_code
            ))
          "
        >
          <div
            v-if="roomDetails && payload.products[index].product_code"
            :set="
              (mediaDetails = {
                mediaList: getMediaList(roomDetails),
                mainImage: getMainImage(roomDetails)
              })
            "
          >
            <div
              :set="
                (mediaCount =
                  (mediaDetails.mediaList.length || 0) +
                  (mediaDetails.mainImage ? 1 : 0))
              "
              class="mb-3 col-md-12 col-12 py-1"
            >
              <div class="row g-0">
                <div class="col-md-3" v-if="mediaDetails.mainImage">
                  <div
                    @click="
                      $refs.carouselModal.open(
                        getGalleryMediaByCode(
                          'room',
                          payload.products[index].product_code
                        )
                      )
                    "
                    class="picture-wrapper ratio ratio-4x3"
                    :style="{
                      'background-image':
                        'url(' + (mediaDetails.mainImage.url + '?d=400x300)')
                    }"
                  >
                    <div class="image-count">
                      {{ mediaCount }}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="white"
                        class="bi bi-image"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                        ></path>
                        <path
                          d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="col-md-9">
                  <div class="my-3 my-md-0 mx-0 mx-md-3">
                    <div class="row">
                      <div
                        class="d-flex justify-content-start mb-2 room-info-wrapper"
                      >
                        <div>
                          <span class="room-title">{{
                            roomDetails.title
                          }}</span>
                          <span class="room-subtitle ms-2">
                            <span class="room-title">&nbsp;</span>
                            <span>
                              {{
                                $tc("form.persons_x", 2, {
                                  min: roomDetails.min,
                                  max: roomDetails.max
                                })
                              }}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <p class="" v-html="roomDetails.description"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="row">
              <div class="col-md-4 col-12 py-3">
                <label :for="'board-select' + index" class="form-label">
                  {{
                    customTranslations.get("%label.form.board%") ||
                    $t("form.mealplan")
                  }}
                </label>
                <select
                  :disabled="!showMealplans"
                  class="form-select form-control"
                  :id="'board-select' + index"
                  v-model="payload.products[index].mealplan_code"
                >
                  <option v-if="availableMealPlans.length > 1" value="">
                    {{
                      customTranslations.get("%label.form.any_board%") ||
                      $t("form.board.any")
                    }}
                  </option>
                  <option
                    :key="boardIndex"
                    v-for="(board, boardIndex) in availableMealPlans"
                    :value="board.external_code"
                  >
                    {{ board.title }}
                  </option>
                </select>
              </div>
              <div class="col-md-4 col-lg-3 col-12 py-3">
                <label for="adults-input" class="form-label">
                  {{
                    customTranslations.get("%label.form.adults%") ||
                    $tc("form.adult_pl", 2)
                  }}
                </label>
                <div class="form-group d-flex adult-input-wrapper">
                  <input
                    type="text"
                    id="adults-input"
                    class="form-control"
                    aria-describedby="basic-addon2"
                    readonly
                    :value="getGuestsLength('adults', index)"
                  />
                  <button
                    class="btn btn-outline-secondary plus-minus-button"
                    type="button"
                    :disabled="
                      getGuestsLength('adults', index) >=
                      roomDetails.max - getGuestsLength('children', index)
                    "
                    @click="increment(index, 'adults')"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-plus"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                      />
                    </svg>
                  </button>
                  <button
                    @click="decrement(index, 'adults')"
                    :disabled="
                      roomDetails.min === getGuestsLength('adults', index)
                    "
                    class="btn btn-outline-secondary plus-minus-button"
                    type="button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-dash"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div
                class="col-md-4 col-lg-3 col-12 py-3"
                v-if="config.config.show_children_selector"
              >
                <label for="children-input" class="form-label">
                  {{
                    customTranslations.get("%label.form.children%") ||
                    $tc("form.child_pl", 2)
                  }}
                </label>
                <div class="form-group d-flex children-input-wrapper">
                  <input
                    :value="getGuestsLength('children', index)"
                    type="text"
                    class="form-control"
                    aria-describedby="basic-addon2"
                    id="children-input"
                    readonly
                  />
                  <button
                    @click="increment(index, 'child')"
                    :disabled="
                      roomDetails &&
                      roomDetails.max - getGuestsLength('adults', index) <=
                        getGuestsLength('children', index)
                    "
                    class="btn btn-outline-secondary plus-minus-button"
                    type="button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-plus"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                      />
                    </svg>
                  </button>
                  <button
                    :disabled="!getGuestsLength('children', index)"
                    @click="decrement(index, 'children')"
                    class="btn btn-outline-secondary plus-minus-button"
                    type="button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-dash"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="data.children.filter((p) => p.room == index).length > 0">
              <div class="row">
                <template
                  v-for="(childObject, puIndex) in data.children.filter(
                    (p) => p.room == index
                  )"
                >
                  <div
                    class="col-12 col-md-2 mb-3"
                    :key="puIndex"
                    v-if="childObject.room == index"
                  >
                    <label
                      :for="'child-age-input-' + puIndex"
                      class="form-label"
                      >{{ $t("form.age_child_x", { x: puIndex + 1 }) }}:</label
                    >
                    <select
                      class="form-select form-control"
                      :id="'child-age-input-' + puIndex"
                      @change="
                        setChildAge(
                          index,
                          puIndex,
                          $event.target.value,
                          puIndex
                        )
                      "
                      :value="
                        data.children.filter((p) => p.room == index)[puIndex]
                          .age
                      "
                    >
                      <template v-for="age in childrenAgeArray">
                        <option :value="age" :key="age">
                          {{ $tc("form.children.age_x", age, { count: age }) }}
                        </option>
                      </template>
                    </select>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="d-grid gap-2 d-flex room-actions-wrapper">
            <button
              v-if="payload.products.length - 1 == index"
              @click="addRoom()"
              type="button"
              class="btn general-button"
              id="add-room-button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-plus"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                ></path>
              </svg>
              {{
                customTranslations.get("%label.fields.room.add%") ||
                $t("form.room.add")
              }}
            </button>
            <button
              @click="removeRoom(index)"
              type="button"
              class="btn general-button"
              id="remove-room-button"
              v-if="index > 0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-trash"
                viewBox="0 0 16 16"
              >
                <path
                  d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                ></path>
                <path
                  fill-rule="evenodd"
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                ></path>
              </svg>
              {{ $t("form.room.remove") }}
            </button>
          </div>
        </div>
      </div>
    </section>
    <section class="container px-0 mt-5" ref="required_data">
      <div class="row">
        <div
          class="col-md-4 col-12 py-3"
          v-if="fieldProperty('name_prefix_slug').show"
        >
          <label for="gender-selector" class="form-label">
            {{
              customTranslations.get("%label.form.salutation%") ||
              $t("form.gender_prefix.label")
            }}
            {{ fieldProperty("name_prefix_slug").mandatory ? "* " : "" }}
          </label>
          <select
            class="form-select form-control"
            id="gender-selector"
            v-model="payload.customer.name_prefix_slug"
            @change="
              errors.indexOf('name_prefix_slug') > -1
                ? validate('name_prefix_slug')
                : null
            "
            @blur="validate('name_prefix_slug')"
            :class="{ 'is-invalid': errors.indexOf('name_prefix_slug') > -1 }"
          >
            <option value="">-</option>
            <option value="mr">{{ $t("form.gender_prefix.mr") }}</option>
            <option value="ms">{{ $t("form.gender_prefix.ms") }}</option>
            <option value="family">
              {{ $t("form.gender_prefix.family") }}
            </option>
            <option value="company">
              {{
                customTranslations.get("%label.fields.business%") ||
                $t("form.gender_prefix.company")
              }}
            </option>
          </select>
          <div class="invalid-feedback">
            {{
              $t("form.error.hint_missing", {
                field:
                  customTranslations.get("%label.form.salutation%") ||
                  $t("form.gender_prefix.label")
              })
            }}
          </div>
        </div>
        <template v-if="payload.customer.name_prefix_slug === 'company'">
          <div class="col-md-8 col-12 py-3" />
          <div class="col-md-4 col-12 py-3">
            <label for="companyname-input" class="form-label"
              >{{
                customTranslations.get("%label.fields.business_name%") ||
                $t("form.companyname")
              }}{{ "* " }}</label
            >
            <input
              v-model="payload.customer.companyname"
              :class="{ 'is-invalid': errors.indexOf('companyname') > -1 }"
              class="form-control"
              id="companyname-input"
              @input="
                errors.indexOf('companyname') > -1
                  ? validate('companyname')
                  : null
              "
              @blur="validate('companyname')"
            />
            <div class="invalid-feedback">
              {{
                $t("form.error.hint_missing", { field: $t("form.companyname") })
              }}
            </div>
          </div>
        </template>
        <div
          class="col-md-4 col-12 py-3"
          v-if="fieldProperty('firstname').show"
        >
          <label for="firstname-input" class="form-label">
            {{
              customTranslations.get("%label.form.firstname%") ||
              $t("form.firstname")
            }}
            {{ fieldProperty("firstname").mandatory ? "* " : "" }}
          </label>
          <input
            v-model="payload.customer.firstname"
            :class="{ 'is-invalid': errors.indexOf('firstname') > -1 }"
            class="form-control"
            id="firstname-input"
            @input="
              errors.indexOf('firstname') > -1 ? validate('firstname') : null
            "
            @blur="validate('firstname')"
          />
          <div class="invalid-feedback">
            {{
              $t("form.error.hint_missing", {
                field:
                  customTranslations.get("%label.form.firstname%") ||
                  $t("form.firstname")
              })
            }}
          </div>
        </div>
        <div class="col-md-4 col-12 py-3" v-if="fieldProperty('lastname').show">
          <label for="surname-input" class="form-label">
            {{
              customTranslations.get("%label.form.lastname%") ||
              $t("form.lastname")
            }}
            {{ fieldProperty("lastname").mandatory ? "* " : "" }}
          </label>
          <input
            v-model="payload.customer.lastname"
            :class="{ 'is-invalid': errors.indexOf('lastname') > -1 }"
            class="form-control"
            id="surname-input"
            @input="
              errors.indexOf('lastname') > -1 ? validate('lastname') : null
            "
            @blur="validate('lastname')"
          />
          <div class="invalid-feedback">
            {{
              $t("form.error.hint_missing", {
                field:
                  customTranslations.get("%label.form.lastname%") ||
                  $t("form.lastname")
              })
            }}
          </div>
        </div>
      </div>
    </section>
    <section
      class="container px-0"
      v-if="fieldProperty('email').show || fieldProperty('phone').show"
    >
      <div class="row">
        <div class="col-md-6 col-12 py-3" v-if="fieldProperty('email').show">
          <label for="email-input" class="form-label">
            {{
              customTranslations.get("%label.form.email%") || $t("form.email")
            }}
            {{ fieldProperty("email").mandatory ? "* " : "" }}
          </label>
          <input
            type="email"
            class="form-control email"
            v-model="email"
            id="email-check"
          />
          <input
            v-model="payload.customer.email"
            :class="{ 'is-invalid': errors.indexOf('email') > -1 }"
            class="form-control"
            type="email"
            id="email-input"
            @input="errors.indexOf('email') > -1 ? validate('email') : null"
            @blur="validate('email')"
          />
          <div class="invalid-feedback">
            {{
              $t("form.error.hint_invalid", {
                field:
                  customTranslations.get("%label.form.email%") ||
                  $t("form.email")
              })
            }}
          </div>
        </div>
        <div class="col-md-6 col-12 py-3" v-if="fieldProperty('phone').show">
          <label for="phone-input" class="form-label">
            {{
              customTranslations.get("%label.form.phone%") || $t("form.phone")
            }}
            {{ fieldProperty("phone").mandatory ? "* " : "" }}
          </label>
          <VueTelInput
            :key="`phone_input_${defaultCountry.key}`"
            :default-country="defaultCountry.value"
            id="phone-input"
            class="form-control"
            :inputOptions="{ placeholder: '', styleClasses: 'form-control' }"
            mode="international"
            v-model="payload.customer.phone"
            @input="errors.indexOf('phone') > -1 ? validate('phone') : null"
            @blur="validate('phone')"
            :class="{ 'is-invalid': errors.indexOf('phone') > -1 }"
          />
          <div class="invalid-feedback">
            {{
              $t("form.error.hint_invalid", {
                field:
                  customTranslations.get("%label.form.phone%") ||
                  $t("form.phone")
              })
            }}
          </div>
        </div>
      </div>
    </section>
    <section class="container px-0">
      <div class="row">
        <div class="col-md-12 col-12 py-3" v-if="fieldProperty('street').show">
          <label for="adress-input" class="form-label">
            {{
              customTranslations.get("%label.form.street%") ||
              $t("form.street")
            }}{{ fieldProperty("street").mandatory ? "* " : "" }}
          </label>
          <input
            type="text"
            class="form-control"
            v-model="payload.customer.street"
            id="adress-input"
            @input="errors.indexOf('street') > -1 ? validate('street') : null"
            @blur="validate('street')"
            :class="{ 'is-invalid': errors.indexOf('street') > -1 }"
          />
          <div class="invalid-feedback">
            {{
              $t("form.error.hint_missing", {
                field:
                  customTranslations.get("%label.form.street%") ||
                  $t("form.street")
              })
            }}
          </div>
        </div>
        <div class="col-md-2 py-3" v-if="fieldProperty('zip').show">
          <label for="zip-input" class="form-label">
            {{ customTranslations.get("%label.form.zip%") || $t("form.zip") }}
            {{ fieldProperty("zip").mandatory ? "* " : "" }}
          </label>
          <input
            type="text"
            class="form-control"
            id="zip-input"
            v-model="payload.customer.zip"
            @input="errors.indexOf('zip') > -1 ? validate('zip') : null"
            @blur="validate('zip')"
            :class="{ 'is-invalid': errors.indexOf('zip') > -1 }"
          />
          <div class="invalid-feedback">
            {{
              $t("form.error.hint_missing", {
                field:
                  customTranslations.get("%label.form.zip%") || $t("form.zip")
              })
            }}
          </div>
        </div>
        <div class="col-md-6 py-3" v-if="fieldProperty('city').show">
          <label for="city-input" class="form-label">
            {{ customTranslations.get("%label.form.city%") || $t("form.city") }}
            {{ fieldProperty("city").mandatory ? "* " : "" }}</label
          >
          <input
            type="text"
            class="form-control"
            v-model="payload.customer.city"
            id="city-input"
            @input="errors.indexOf('city') > -1 ? validate('city') : null"
            @blur="validate('city')"
            :class="{ 'is-invalid': errors.indexOf('city') > -1 }"
          />
          <div class="invalid-feedback">
            {{
              $t("form.error.hint_missing", {
                field:
                  customTranslations.get("%label.form.city%") || $t("form.city")
              })
            }}
          </div>
        </div>
        <div class="col-md-4 py-3" v-if="fieldProperty('country_iso').show">
          <label for="country-input" class="form-label">
            {{
              customTranslations.get("%label.form.country%") ||
              $t("form.country_iso")
            }}
            {{ fieldProperty("country_iso").mandatory ? "* " : "" }}
          </label>
          <select
            id="country-input"
            v-model="payload.customer.country_iso"
            class="form-select form-control"
            @change="
              errors.indexOf('country_iso') > -1
                ? validate('country_iso')
                : null
            "
            @blur="validate('country_iso')"
            :class="{ 'is-invalid': errors.indexOf('country_iso') > -1 }"
          >
            <option
              :key="country.value"
              :value="country.value"
              v-for="country in countries"
            >
              {{ country.text }}
            </option>
          </select>
          <div class="invalid-feedback">
            {{
              $t("form.error.hint_missing", {
                field:
                  customTranslations.get("%label.form.country%") ||
                  $t("form.country_iso")
              })
            }}
          </div>
        </div>
        <div class="col-md-12 col-12 py-3" v-if="fieldProperty('note').show">
          <label for="form-notes" class="form-label">
            {{
              customTranslations.get("%label.fields.note%") || $t("form.note")
            }}
            {{ fieldProperty("note").mandatory ? "* " : "" }}
          </label>
          <textarea
            class="form-control"
            id="form-notes"
            v-model="payload.note"
            rows="4"
            @input="errors.indexOf('note') > -1 ? validate('note') : null"
            @blur="validate('note')"
            :class="{ 'is-invalid': errors.indexOf('note') > -1 }"
          ></textarea>
          <div class="invalid-feedback">
            {{
              $t("form.error.hint_missing", {
                field:
                  customTranslations.get("%label.fields.note%") ||
                  $t("form.note")
              })
            }}
          </div>
        </div>
      </div>
    </section>
    <section class="container px-0 pt-4 custom-fields-wrapper">
      <div class="row">
        <template v-if="payload.custom_fields.length">
          <div
            class="col-md-12 col-12 py-3"
            v-for="(checkboxField, checkboxFieldI) in groupedCustomFields[
              'checkbox_group'
            ]"
            :key="`checkbox_field_${checkboxFieldI}`"
          >
            <label
              :for="`checkbox-field-${checkboxFieldI}-input`"
              class="form-label"
            >
              <span
                v-if="
                  $i18n.locale in checkboxField.translations &&
                  checkboxField.translations[$i18n.locale].title
                "
              >
                {{ checkboxField.translations[$i18n.locale].title }}
              </span>
              <span
                v-else-if="
                  fallbackLocale in checkboxField.translations &&
                  checkboxField.translations[fallbackLocale].title
                "
              >
                {{ checkboxField.translations[fallbackLocale].title }}
              </span>
              <span>&nbsp;</span>
            </label>
            <div class="d-flex flex-wrap" v-if="'checkboxes' in checkboxField">
              <div
                v-for="(checkbox, checkboxI) in checkboxField['checkboxes']"
                :key="`checkbox_${checkboxFieldI}_${checkboxI}`"
                class="form-check mb-1 mt-0"
              >
                <input
                  v-model="
                    payload.custom_fields[checkboxField.index].value[checkboxI]
                      .value
                  "
                  class="form-check-input"
                  type="checkbox"
                  multiple
                />
                <div class="ms-2">
                  <div class="d-flex custom-check-label d-inline-block">
                    <label class="form-check-label">
                      <span
                        v-if="
                          $i18n.locale in checkbox.translations &&
                          checkbox.translations[$i18n.locale].title
                        "
                      >
                        {{ checkbox.translations[$i18n.locale].title }}
                      </span>
                      <span
                        v-else-if="
                          fallbackLocale in checkbox.translations &&
                          checkbox.translations[fallbackLocale].title
                        "
                      >
                        {{ checkbox.translations[fallbackLocale].title }}
                      </span>
                      <span>&nbsp;</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-4 col-12 py-3"
            v-for="(textField, textFieldI) in groupedCustomFields['text_field']"
            :key="`text_field_${textFieldI}`"
          >
            <label :for="`text-field-${textFieldI}-input`" class="form-label">
              <span
                v-if="
                  $i18n.locale in textField.translations &&
                  textField.translations[$i18n.locale].title
                "
              >
                {{ textField.translations[$i18n.locale].title }}
              </span>
              <span
                v-else-if="
                  'en' in textField.translations &&
                  textField.translations[fallbackLocale].title
                "
              >
                {{ textField.translations[fallbackLocale].title }}
              </span>
              <span>&nbsp;</span>
            </label>
            <input
              v-model="payload.custom_fields[textField.index]['value']"
              class="form-control"
              :id="`text-field-${textFieldI}-input`"
            />
          </div>
        </template>
      </div>
    </section>
    <section
      class="container px-0 consents-wrapper"
      ref="privacy_consent"
      :class="{ 'my-1': payload.custom_fields.length }"
    >
      <div class="row py-0">
        <div class="col-md-12 col-12 py-3">
          <div
            v-for="consent in config.consent_requests.data"
            :key="`${consent.name}_input_wrapper`"
            class="form-check my-3"
          >
            <input
              v-model="payload.customer[consent.name]"
              :name="consent.name"
              :class="{ 'is-invalid': errors.indexOf(consent.name) > -1 }"
              :key="consent.name"
              @change="
                errors.indexOf(consent.name) > -1
                  ? validate(consent.name)
                  : null
              "
              @blur="validate(consent.name)"
              class="form-check-input"
              type="checkbox"
              :id="consent.name + '-checkmark'"
            />
            <div class="ms-2" v-if="!isPrivacyApp && consent.url">
              <div class="custom-check-label d-inline-block">
                <label
                  class="form-check-label"
                  :for="consent.name + '-checkmark'"
                >
                  <svg
                    v-if="consent.name == 'whatsapp_consent'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="currentColor"
                    class="bi bi-whatsapp"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994
                                            14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592
                                            6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729
                                            0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z
                                        "
                    />
                  </svg>
                  <a class="show-as-link" :href="consent.url" target="_blank">{{
                    consent.label
                  }}</a>
                </label>
              </div>
            </div>
            <div class="ms-2" v-else>
              <div class="d-flex custom-check-label d-inline-block">
                <label
                  class="form-check-label"
                  :for="consent.name + '-checkmark'"
                >
                  <svg
                    v-if="consent.name == 'whatsapp_consent'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="currentColor"
                    class="bi bi-whatsapp"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994
                                            14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592
                                            6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729
                                            0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z
                                        "
                    />
                  </svg>
                  <span
                    :style="
                      consent.name == 'whatsapp_consent'
                        ? 'margin-left: 8px;'
                        : ''
                    "
                    >{{ consent.label }}</span
                  >
                </label>
                <div
                  class="d-flex custom-check"
                  :class="{
                    checked:
                      consents_open[consent.name] ||
                      payload.customer[consent.name]
                  }"
                  @click="alertCustom(consent.name)"
                ></div>
              </div>
              <transition name="slide">
                <div
                  v-if="
                    (consent.name == 'whatsapp_consent' &&
                      payload.customer[consent.name]) ||
                    (consent.text && payload.customer[consent.name]) ||
                    consents_open[consent.name]
                  "
                >
                  <div
                    class="col-12 col-md-4 col-lg-3 pt-2"
                    v-if="
                      consent.name == 'whatsapp_consent' &&
                      (payload.customer[consent.name] ||
                        consents_open[consent.name])
                    "
                  >
                    <VueTelInput
                      :key="`whatsapp_input_${defaultCountry.key}`"
                      :default-country="defaultCountry.value"
                      id="whatsapp-input"
                      class="form-control"
                      mode="international"
                      :inputOptions="{
                        placeholder: '',
                        styleClasses: 'form-control'
                      }"
                      v-model="payload.customer.whatsapp_number"
                      @input="
                        errors.indexOf('whatsapp_number') > -1
                          ? validate('whatsapp_number')
                          : null
                      "
                      @blur="validate('whatsapp_number')"
                      :class="{
                        'is-invalid': errors.indexOf('whatsapp_number') > -1
                      }"
                    />
                    <div class="invalid-feedback">
                      {{
                        $t("form.error.hint_invalid", {
                          field:
                            customTranslations.get("%label.form.phone%") ||
                            $t("form.phone")
                        })
                      }}
                    </div>
                    <!--                                    <p v-if="consent.text" style="font-size: small;" class="mt-1 mb-0">-->
                    <!--                                        <span v-html="consent.text"></span>-->
                    <!--                                    </p>-->
                  </div>
                  <p
                    v-if="
                      payload.customer[consent.name] ||
                      consents_open[consent.name]
                    "
                    :role="
                      'privacy_text' in config &&
                      config.privacy_text &&
                      consent.name === 'privacy_consent'
                        ? 'button'
                        : ''
                    "
                    @click="
                      'privacy_text' in config &&
                        config.privacy_text &&
                        consent.name === 'privacy_consent' &&
                        openConsent(consent, config.privacy_text)
                    "
                    class="consent-description mt-2 mb-0"
                    v-html="consent.text"
                  />
                </div>
              </transition>
            </div>
            <div class="ms-2 invalid-feedback">
              {{ $t("form.error.consent_mandatory") }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="container px-0 actions-wrapper">
      <div class="row">
        <div
          class="d-grid d-md-flex text-md-end py-3"
          :class="isMobile ? 'justify-content-center' : 'justify-content-end'"
        >
          <button
            :disabled="request.state"
            type="button"
            class="btn custom-button"
            @click="submitForm()"
          >
            <span v-if="request.state" class="spinner-border" role="status" />
            {{
              customTranslations.get("%label.form.send_request%") ||
              $t("form.submit")
            }}
          </button>
        </div>
      </div>
    </section>
    <transition name="fade">
      <div id="popup" v-if="popup.state" :class="popup.type">
        <h5 v-html="popup.title" v-if="popup.title"></h5>
        <p v-html="popup.message" v-if="popup.message"></p>
      </div>
    </transition>
    <ConsentDialog ref="consentDialog" />
    <CarouselModal ref="carouselModal" />
  </div>
</template>

<script>
import HotelDatePicker from "@/plugins/DatePicker/HotelDatePicker";
import { VueTelInput } from "vue-tel-input";
import { countryList } from "@/models/countryList";
import cssVars from "css-vars-ponyfill";
import ConsentDialog from "@/components/ConsentDialog.vue";
import CarouselModal from "@/components/CarouselModal.vue";
import { cloneDeep } from "lodash";
import * as EmailValidator from "email-validator";
import CustomField from "@/models/customField";

const { dtoToModel: customFieldDtoToModel, modelToDto: customFieldModelToDto } =
  new CustomField();
export default {
  name: "BookingRequest",
  components: { CarouselModal, ConsentDialog, HotelDatePicker, VueTelInput },
  props: {
    config: Object,
    settings: Object,
    isMobile: Boolean
  },
  data() {
    return {
      fallbackLocale: "en",
      defaultCountry: {
        value: "",
        key: 1
      },
      ready: false,
      email: "", //
      request: {
        state: false
      },
      popup: {
        state: false,
        type: "msg-success",
        message: ""
      },
      errors: [],
      countries: countryList
        .map((country) => ({
          value: country.alpha3,
          code: country.code,
          pinned: country.pinned,
          text:
            typeof country.translations.data[this.$i18n.locale] != "undefined"
              ? country.translations.data[this.$i18n.locale].name
              : country.translations.data.en.name
        }))
        .sort(function (a, b) {
          if (a.pinned < b.pinned) return +1;
          if (a.pinned > b.pinned) return -1;
          if (a.text > b.text) return +1;
          if (a.text < b.text) return -1;
          return 0;
        })
        .map((country) => ({
          value: country.value,
          text: country.text
        })),
      phone: "",
      dates: null,
      data: {
        adults: [],
        children: []
      },
      sampleStructure: {
        room: {
          mealplan_code: "",
          rateplan_code: "",
          product_code: "",
          quantity: 1,
          pu_participants: []
        },
        participants: {
          age_code: null,
          age: null
        }
      },
      payload: {
        custom_fields: [],
        from_date: "",
        to_date: "",
        customer: {
          language: this.$i18n.locale,
          name_prefix_slug: "",
          companyname: "",
          firstname: "",
          lastname: "",
          street: "",
          city: "",
          zip: "",
          country_iso: "",
          email: "",
          phone: ""
        },
        products: [],
        note: "",
        utm_source: null,
        utm_medium: null,
        utm_campaign: null,
        utm_content: null,
        utm_term: null
      },
      carousel: null,
      modal: null,
      consents_open: {},
      default_occupancy: {
        children: 10,
        adults: 1,
        max: {
          adults: 10,
          children: 10
        }
      },
      groupedCustomFields: {
        text_field: [],
        checkbox_group: []
      }
    };
  },
  watch: {
    "payload.customer.whatsapp_consent"(value) {
      if (value) {
        this.payload.customer.whatsapp_number = this.payload.customer.phone;
      } else {
        delete this.payload.customer.whatsapp_number;
      }
    },
    state: {
      handler: function (val) {
        this.$emit("widget-event", "onStateChanged", val);
      },
      deep: true
    }
  },
  mounted() {
    var head = document.getElementsByTagName("head")[0];
    var link = document.createElement("link");
    link.id = "googleFont";
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = "https://fonts.googleapis.com/css?family=Open+Sans:400,500,700";
    link.media = "all";
    head.appendChild(link);

    this.availableRooms.sort(function (a, b) {
      if (a.priority < b.priority) return +1;
      if (a.priority > b.priority) return -1;
      if (a.priority == b.priority) return 0;
    });

    let defaultCssSet = {
      "--cta_background_color": "#494d50",
      "--cta_font_color": "#ffffff",
      "--cta_font_family": "Arial, Verdana, sans-serif",
      "--general_font_color": "#494d50",
      "--general_font_family": "'Open Sans', sans-serif",
      "--general_background_color": "#FFFFFF"
    };
    // console.log(defaultCssSet)
    cssVars({ variables: defaultCssSet });
    let vars = {};
    Object.keys(this.config.style).forEach((key) => {
      if (this.config.style?.[key]) {
        Object.keys(this.config.style[key]).forEach((prop) => {
          if (
            this.config.style[key][prop] &&
            this.config.style[key][prop] != ""
          ) {
            vars["--" + key + "_" + prop] = this.config.style[key][prop];
          }
        });
      }
    });
    // console.log(vars)
    cssVars({ variables: vars });

    if (this.config.style_css && this.config.style_css != "") {
      var styleEl = document.createElement("style");
      styleEl.innerHTML = this.config.style_css.replace(/(<([^>]+)>)/gi, "");
      document
        .querySelector("travel-suite-widget")
        .shadowRoot.appendChild(styleEl);
    }

    if (
      "recaptcha" in this.config.config &&
      this.config.config.recaptcha.enabled
    ) {
      if (this.config.config.recaptcha.public_key != "") {
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.src =
          "https://www.google.com/recaptcha/api.js?render=" +
          this.config.config.recaptcha.public_key;
        document.head.appendChild(s);
      }
    }
    let filteredProps = ["lang", "uuid"];
    let objectArray = [];
    let expression = /^p([0-9]+)_([a-z_]*)$/im;
    Object.keys(this.settings).forEach((property) => {
      if (filteredProps.indexOf(property) === -1) {
        let caughtArray = expression.exec(property);
        if (!caughtArray) {
          if (property === "to_date" || property === "from_date") {
            if (this.isValidDate(this.settings[property])) {
              this.payload[property] = this.settings[property];
            } else {
              console.log("Invalid Date: " + property, this.settings[property]);
            }
          } else {
            this.payload[property] = this.settings[property];
          }
        } else {
          const [productIndex, productProperty] = [
            caughtArray[1],
            caughtArray[2]
          ];
          if (typeof objectArray[productIndex] == "undefined")
            objectArray[productIndex] = {};
          objectArray[productIndex][productProperty] = this.settings[property];
        }
      }
    });

    //Select first offer
    let defaultOfferCode =
      (this.config.config?.offers_show && this.config.config?.offers_default) ||
      "";
    if (typeof defaultOfferCode === "number") {
      defaultOfferCode =
        this.availableOffers.find((offer) => offer.id === defaultOfferCode)
          ?.external_code || "";
    }
    //Select first room
    let defaultRoomCode =
      (this.config.config?.rooms_show && this.config.config?.rooms_default) ||
      "";
    if (typeof defaultRoomCode === "number") {
      defaultRoomCode =
        this.availableRooms.find((room) => room.id === defaultRoomCode)
          ?.external_code || "";
    }
    const confMealPlanDefault = this.config.config?.mealplans_default;
    const defaultMealplanCode =
      this.config.config?.mealplans_show &&
      confMealPlanDefault &&
      this.config.mealplans.data
        ?.map((el) => el?.external_code)
        ?.includes(confMealPlanDefault)
        ? confMealPlanDefault
        : (this.config.config?.mealplans?.length === 1 &&
            this.config.config.mealplans?.[0]) ||
          "";
    if (objectArray.length > 0) {
      objectArray.forEach((productObject) => {
        if (productObject) {
          if (!productObject?.mealplan_code) {
            productObject.mealplan_code =
              defaultMealplanCode ||
              (this.config.config?.mealplans?.length === 1 &&
                this.config.config.mealplans?.[0]) ||
              "";
          } else {
            if (!this.getMealPlanByExtCode(productObject.mealplan_code)) {
              productObject.mealplan_code =
                defaultMealplanCode ||
                (this.config.config?.mealplans?.length === 1 &&
                  this.config.config.mealplans?.[0]) ||
                "";
            }
          }
          if (!productObject?.rateplan_code) {
            productObject.rateplan_code = defaultOfferCode;
          } else {
            if (!this.getOfferByExtCode(productObject.rateplan_code)) {
              productObject.rateplan_code = defaultOfferCode;
            }
          }
          if (!productObject?.product_code) {
            productObject.product_code = defaultRoomCode;
          } else {
            if (
              !this.availableRooms.find((datum) => {
                return datum.external_code === productObject.product_code;
              })
            ) {
              productObject.product_code = defaultRoomCode;
            }
          }
          let productIndex = this.addRoom(productObject);
          if ("guests" in productObject && productObject.guests !== "") {
            this.setGuestsFromQueryParams(
              productObject.guests,
              productIndex,
              productObject.product_code
            );
          } else {
            this.setStdGuestForProduct(
              productIndex,
              productObject.product_code
            );
          }
        }
      });
    } else {
      let roomIndex = this.addRoom();
      this.selectRoom(roomIndex, defaultRoomCode);
      this.payload.products[roomIndex].mealplan_code =
        defaultMealplanCode ||
        (this.config.config?.mealplans?.length === 1 &&
          this.config.config.mealplans?.[0]) ||
        "";
      this.payload.products[roomIndex].rateplan_code = defaultOfferCode;
    }
    if (this.config.config?.custom_fields) {
      const customFields = customFieldDtoToModel(
        this.config.config.custom_fields
      );
      this.groupedCustomFields["text_field"] = customFields
        .filter((el) => el.type === "text_field")
        .sort((a, b) => b.priority - a.priority);
      this.groupedCustomFields["checkbox_group"] = customFields
        .filter((el) => el.type === "checkbox_group")
        .sort((a, b) => b.priority - a.priority);
      this.payload.custom_fields = customFieldModelToDto(customFields);
    }
    this.config.consent_requests.data.forEach((consent) => {
      this.consents_open[consent.name] = false;
    });

    if (!("show_children_selector" in this.config.config)) {
      this.config.config.show_children_selector = true;
    }
    this.setDefaultValidations();
    this.setAutoDefaultCountry();
    this.ready = true;
  },
  computed: {
    customTranslations() {
      return new Map(
        this.config?.config?.custom_labels?.map((obj) => {
          return [
            obj.placeholder,
            obj.translations?.[this.$i18n?.locale]?.label || ""
          ];
        })
      );
    },
    childrenAgeArray() {
      let agesArray = [];
      for (let i = this.childrenMinAge; i <= this.childrenMaxAge; i++) {
        agesArray.push(i);
      }
      return agesArray;
    },
    childrenMinAge() {
      return this.config?.config?.children_min_age || 0;
    },
    childrenMaxAge() {
      return this.config?.config?.children_max_age || 17;
    },
    showMealplans() {
      return this.config.config?.mealplans_show;
    },
    showOffers() {
      return this.config.config?.offers_show;
    },
    showRooms() {
      return this.config.config?.rooms_show;
    },
    availableOffers() {
      const { from_date, to_date } = this.payload;
      let [mFrom, mTo] = [null, null];
      if (
        from_date &&
        to_date &&
        this.$moment(from_date).isValid() &&
        this.$moment(to_date).isValid()
      ) {
        [mFrom, mTo] = [
          this.$moment(from_date).startOf("day").add(1, "minute"),
          this.$moment(to_date).endOf("day").subtract(1, "minute")
        ];
      }
      return this.config?.offers?.data
        ?.filter((offer) => {
          return (
            !!offer.external_code &&
            (!offer.periods ||
              !offer.periods?.length ||
              offer.periods?.some((period) => {
                if (
                  !mFrom ||
                  !mTo ||
                  !period.from ||
                  !period.to ||
                  (period.from &&
                    period.to &&
                    (!this.$moment(period.from).isValid() ||
                      !this.$moment(period.to).isValid()))
                ) {
                  return false;
                }
                return this.checkIfPeriodIsValid(
                  mFrom,
                  mTo,
                  offer.arrival_departure_exact,
                  period
                );
              }))
          );
        })
        .map((offer) => {
          if (!offer.periods?.length) {
            return offer;
          }
          const ret = cloneDeep(offer);
          ret.periods = offer.periods.filter((period) => {
            return this.checkIfPeriodIsValid(
              mFrom,
              mTo,
              offer.arrival_departure_exact,
              period
            );
          });
          return ret;
        });
    },
    availableRooms() {
      return (
        this.config?.rooms?.data?.filter((el) => !!el?.external_code) || []
      );
    },
    availableMealPlans() {
      return (
        this.config?.mealplans?.data?.filter((el) => !!el.external_code) || []
      );
    },
    isPrivacyApp() {
      return (
        "privacy_app" in this.config.config && this.config.config.privacy_app
      );
    },
    state() {
      let state = {
        from_date: this.payload.from_date,
        to_date: this.payload.to_date,
        products: [],
        customer: this.payload.customer,
        note: this.payload.note,
        utm_source: this.payload.utm_source,
        utm_medium: this.payload.utm_medium,
        utm_campaign: this.payload.utm_campaign,
        utm_content: this.payload.utm_content,
        utm_term: this.payload.utm_term,
        custom_fields: this.payload.custom_fields
      };
      this.payload.products.forEach((product, index) => {
        state.products[index] = {
          product_code: product.product_code,
          mealplan_code: product.mealplan_code,
          rateplan_code: product.rateplan_code
        };
        state.products[index].participants = [
          ...this.data.children
            .filter((pu) => pu.room == index)
            .map((pu) => ({
              age: pu.age,
              age_code: pu.age_code
            })),
          ...this.data.adults
            .filter((pu) => pu.room == index)
            .map((pu) => ({
              age: pu.age,
              age_code: pu.age_code
            }))
        ];
      });
      return state;
    }
  },
  methods: {
    async setAutoDefaultCountry() {
      try {
        const response = await this.$http.get("https://ip2c.org/s");
        if (response?.status === 200) {
          this.defaultCountry.value = response?.data?.split(";")?.[1] || "IT";
        }
      } catch (e) {
        this.defaultCountry.value = "IT";
      } finally {
        this.defaultCountry.key++;
      }
    },
    setDefaultValidations() {
      this.errors = [];
      if (
        "whatsapp_consent" in this.payload.customer &&
        this.payload.customer.whatsapp_consent
      ) {
        this.config.config.fields["whatsapp_number"] = {
          show: true,
          mandatory: true
        };
      } else {
        "whatsapp_number" in this.config.config.fields
          ? delete this.config.config.fields["whatsapp_number"]
          : null;
      }
      this.config.config.fields["privacy_consent"] = {
        show: true,
        mandatory: true
      };
      this.config.config.fields["to_date"] = { show: true, mandatory: true };
      this.config.config.fields["from_date"] = {
        show: true,
        mandatory: true
      };
    },
    getFormattedPeriod({ from, to }) {
      return `${
        from && this.$moment(from).locale(this.$i18n.locale).format("L")
      } ${to && " - "} ${
        to && this.$moment(to).locale(this.$i18n.locale).format("L")
      }`;
    },
    checkIfPeriodIsValid(mFrom, mTo, arrivalDepartureExact, { from, to }) {
      const [mPeriodFrom, mPeriodTo] = [
        this.$moment(from).startOf("day"),
        this.$moment(to).endOf("day")
      ];
      if (arrivalDepartureExact) {
        return (
          mFrom.isBetween(mPeriodFrom, mPeriodTo) &&
          mTo.isBetween(mPeriodFrom, mPeriodTo)
        );
      }
      return (
        mFrom.isBetween(mPeriodFrom, mPeriodTo) ||
        mTo.isBetween(mPeriodFrom, mPeriodTo)
      );
    },
    openConsent(consent, consentText = "") {
      this.$refs.consentDialog?.openConsent(consent, consentText);
    },
    getGuestsLength(type, productIndex) {
      return (
        this.data[type].filter((el) => el.room == productIndex)?.length || 0
      );
    },
    setStdGuestForProduct(productIndex, productCode) {
      let roomDetails = this.getRoomByExtCode(productCode);
      if (!this.data.adults.find((adult) => adult.room === productIndex)) {
        for (let i = 0; i < (roomDetails?.std || 1); i++) {
          this.data.adults.push({
            age: 18,
            age_code: "adult",
            room: productIndex
          });
        }
      }
    },
    setGuestsFromQueryParams(guests, productIndex, productCode) {
      let ages = guests.split(",");
      const isAdultPreset = ages.some((x) => x >= 18);
      if (!isAdultPreset) this.setStdGuestForProduct(productIndex, productCode);
      let index = {
        adults: 0,
        child: 0
      };
      ages.forEach((age) => {
        let age_code = parseInt(age) >= 18 ? "adults" : "child";

        this.increment(productIndex, age_code, parseInt(age));
        // if (age_code == "child") {
        //     this.setChildAge(productIndex, index[age_code], parseInt(age))
        // }

        index[age_code]++;
      });
    },
    getGalleryMediaByCode(type, code) {
      const mapping = {
        offer: () => this.getOfferByExtCode(code),
        room: () => this.getRoomByExtCode(code)
      };

      const product = mapping[type] || null;
      if (!product) {
        return null;
      }
      return {
        mediaList: this.getMediaList(product()),
        mainImage: this.getMainImage(product())
      };
    },
    getMediaList(product) {
      if (!product) return;
      let mediaList = [];
      if (product?.gallery?.data?.length) {
        let gallery = product.gallery.data.filter(
          (el) => el.id !== (product?.picture?.data?.id || null)
        );
        if (gallery?.length) {
          mediaList = [...mediaList, ...gallery];
        }
      }
      if (product?.picture_floorplan?.data) {
        mediaList.push(product.picture_floorplan.data);
      }
      if (product?.video?.data) {
        mediaList.push(product.video.data);
      }
      if (product?.panorama?.data) {
        mediaList.push(product.panorama.data);
      }
      return mediaList;
    },
    getMainImage(product) {
      return product?.picture?.data || null;
    },
    getRoomByExtCode(extCode) {
      if (!extCode) {
        return {
          min: 1,
          max: 99
        };
      }
      let roomByExtCode = this.availableRooms.find(
        (room) => room.external_code == extCode
      );
      if (!roomByExtCode.min) {
        roomByExtCode.min = 1;
      }
      if (!roomByExtCode.std) {
        roomByExtCode.std = 1;
      }
      if (!roomByExtCode.max) {
        roomByExtCode.max = 99;
      }
      return roomByExtCode;
    },
    getMealPlanByExtCode(extCode) {
      return this.availableMealPlans?.find(
        (mealPlan) => mealPlan.external_code == extCode
      );
    },
    getOfferByExtCode(extCode) {
      return this.availableOffers?.find(
        (offer) => offer.external_code == extCode
      );
    },
    isValidDate(dateString) {
      var regEx = /^\d{4}-\d{2}-\d{2}$/;
      if (!dateString.match(regEx)) return false; // Invalid format
      var d = new Date(dateString);
      var dNum = d.getTime();
      if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
      return d.toISOString().slice(0, 10) === dateString;
    },
    periodSelected(event, checkIn, checkOut) {
      this.setDates("to_date", checkOut);
      this.setDates("from_date", checkIn);
      if (this.errors.indexOf("to_date") > -1) this.validate("to_date");
      if (this.errors.indexOf("from_date") > -1) this.validate("from_date");
    },
    alertCustom(name) {
      // alert(name)
      // console.log(this.consents_open[name])
      this.consents_open[name] = !this.consents_open[name];
      this.$forceUpdate();
    },
    setRatePlan(roomIndex) {
      if (
        this.payload.products[roomIndex].rateplan_code &&
        this.availableOffers?.findIndex(
          (offer) =>
            offer.external_code ===
            this.payload.products[roomIndex].rateplan_code
        ) >= 0
      ) {
        return;
      }
      if (
        this.settings?.[`p${roomIndex}_rateplan_code`] &&
        this.availableOffers?.findIndex(
          (offer) =>
            offer.external_code === this.settings[`p${roomIndex}_rateplan_code`]
        ) >= 0
      ) {
        this.payload.products[roomIndex].rateplan_code =
          this.settings[`p${roomIndex}_rateplan_code`];
      } else {
        let defaultOfferCode =
          (this.config.config?.offers_show &&
            this.config.config?.offers_default) ||
          "";
        if (typeof defaultOfferCode === "number") {
          defaultOfferCode =
            this.availableOffers.find((offer) => offer.id === defaultOfferCode)
              ?.external_code || "";
        }
        this.payload.products[roomIndex].rateplan_code = defaultOfferCode;
      }
    },
    selectRoom(index, roomCode) {
      this.payload.products[index].product_code = roomCode ? roomCode : "";
      let roomDetails = this.getRoomByExtCode(roomCode);
      this.data.adults = this.data.adults.filter(function (obj) {
        return obj.room !== index;
      });
      if (this.settings && [`p${index}_mealplan_code`] in this.settings) {
        const existingMealPlan = this.availableMealPlans.find(
          (el) => el?.external_code === this.settings[`p${index}_mealplan_code`]
        );
        this.payload.products[index].mealplan_code =
          existingMealPlan?.external_code ||
          (this.config.config?.mealplans?.length === 1 &&
            this.config.config.mealplans?.[0]) ||
          "";
      }

      this.setRatePlan(index);
      if (this.settings && [`p${index}_guests`] in this.settings) {
        this.data.children = this.data.children.filter(function (obj) {
          return obj.room !== index;
        });
        this.setGuestsFromQueryParams(
          this.settings[`p${index}_guests`],
          index,
          this.payload.products[index].product_code
        );
      } else if (roomDetails && "std" in roomDetails && roomDetails.std) {
        for (let i = 0; i < roomDetails.std; i++) {
          this.data.adults.push({ age: 18, age_code: "adult", room: index });
        }
        let childrenArray = this.data.children.filter(
          (item) => item.room === index
        );
        let adultsArray = this.data.adults.filter(
          (item) => item.room === index
        );
        if (roomDetails.max < childrenArray.length + adultsArray.length) {
          let difference =
            roomDetails.max - (adultsArray.length + childrenArray.length);

          for (let i = 0; i < difference * -1; i++) {
            // console.log("children--", this.data.children.filter(item=>item.room==index),this.data.children.filter(item=>item.room==index).length-1, this.data.children.filter(item=>item.room==index).splice(this.data.children.filter(item=>item.room==index).length-1,1))
            let indexToDelete = this.data.children
              .map(function (e) {
                return e.room;
              })
              .indexOf(index);
            this.data.children.splice(indexToDelete, 1);
          }
        }
      } else {
        // this.data.adults = this.data.adults.filter(item => item.room != index)
        if (this.data.adults.filter((item) => item.room == index).length == 0) {
          for (let i = 0; i < this.default_occupancy.adults; i++) {
            this.data.adults.push(
              Object.assign({}, { age: 18, age_code: "adult", room: index })
            );
          }
        }
      }
      // this.settings['p' + index + '_product_code'] = roomCode;
    },
    fieldProperty(field) {
      if (Object.keys(this.config.config.fields).indexOf(field) > -1) {
        return this.config.config.fields[field];
      } else {
        return {};
      }
    },
    showPopup(params) {
      this.popup.title = params.title || null;
      this.popup.message = params.message;
      this.popup.type = params.type;
      this.popup.state = true;
      setTimeout(() => {
        this.popup.state = false;
      }, 5000);
    },
    submitForm() {
      // this.showPopup({type: 'msg-error', message: 'an error has occured'});
      this.validate();
      if (this.errors.length == 0) {
        if (
          "recaptcha" in this.config.config &&
          this.config.config.recaptcha.enabled
        ) {
          let vm = this;
          grecaptcha.ready(function () {
            // eslint-disable-next-line no-undef
            grecaptcha
              .execute(vm.config.config.recaptcha.public_key, {
                action: "submit"
              })
              .then(function (token) {
                vm.submitData(token);
              });
          });
        } else {
          this.submitData();
        }
        this.$forceUpdate();
      } else {
        this.$nextTick(() => {
          const el = this.$refs.widgetBody.querySelector(".is-invalid");
          el
            ? el.scrollIntoView({ block: "center", behavior: "smooth" })
            : null;
        });
      }
    },
    submitData(token = null) {
      this.request.state = true;
      let payload = JSON.parse(JSON.stringify(this.payload));
      if (token) payload.recaptcha_token = token;

      payload.products.forEach((product, index) => {
        payload.products[index].pu_participants = [
          ...this.data.children
            .filter((pu) => pu.room == index)
            .map((pu) => ({
              age: pu.age,
              age_code: pu.age_code
            })),
          ...this.data.adults
            .filter((pu) => pu.room == index)
            .map((pu) => ({
              age: pu.age,
              age_code: pu.age_code
            }))
        ];
      });
      payload.customer.phone = payload.customer.phone || null;
      this.config.consent_requests.data.forEach((consent) => {
        if (!(consent.name in payload.customer))
          payload.customer[consent.name] = false;
      });
      if (this.email == "") {
        if (payload.custom_fields.length) {
          payload.custom_fields = customFieldModelToDto(payload.custom_fields);
        }
        this.$http
          .post("widget/" + this.config.uuid + "/bookingrequest", payload)
          .then((response) => {
            //emit an event on the custom widget element.
            this.$emit("success-event");
            this.$emit("widget-event", "onEnquirySuccess", this.state);
            this.$emit("datalayer-event", {
              event: "generate_lead",
              transaction_id: response.data.data.partner_sale_slug,
              affiliation: "Yanovis Enquiry Widget"
            });
            this.request.state = !this.request.state;
          })
          .catch((error) => {
            let errorTitle = "",
              errorMsg = this.$t("default.error_message");
            this.showPopup({
              title: errorTitle,
              message: errorMsg || null,
              type: "msg-error"
            });
            this.request.state = !this.request.state;
          });
      } else {
        this.showPopup({
          message: this.$t("form.messages.error"),
          type: "msg-error"
        });
        this.request.state = !this.request.state;
      }
    },
    validate(fieldInput = "all") {
      const input =
        fieldInput === "all"
          ? Object.keys(this.config.config.fields)
          : [fieldInput];
      if (
        fieldInput === "all" &&
        this.payload.customer.name_prefix_slug === "company"
      ) {
        input.push("companyname");
      }
      input.forEach((field) => {
        let control;
        if (field === "companyname") {
          control = this.payload.customer?.["companyname"] === "";
        } else if (this.config.config.fields?.[field]?.mandatory) {
          switch (field) {
            case "note":
            case "from_date":
            case "to_date": {
              control = this.payload[field] == "";
              break;
            }
            case "whatsapp_consent": {
              control =
                "whatsapp_consent" in this.payload.customer &&
                this.payload.customer.whatsapp_consent &&
                this.payload.customer.whatsapp_number == "";
              break;
            }
            case "privacy_consent": {
              control =
                Object.keys(this.payload.customer).indexOf("privacy_consent") ==
                  -1 || !this.payload.customer.privacy_consent;
              break;
            }
            default: {
              control = this.payload.customer[field] == "";
            }
          }
        }
        switch (field) {
          case "email": {
            if (
              !this.payload.customer.email ||
              !EmailValidator.validate(this.payload.customer.email)
            )
              control = true;
            break;
          }
          case "phone": {
            const phone = /^\+(?:[0-9] ?){6,14}[0-9]$|^$/;
            if (!phone.test(this.payload.customer.phone)) control = true;
            break;
          }
          case "whatsapp_number": {
            const phone = /^\+(?:[0-9] ?){6,14}[0-9]$|^$/;
            if (!phone.test(this.payload.customer.whatsapp_number))
              control = true;
            break;
          }
        }
        if (control) {
          this.errors.indexOf(field) === -1 && this.errors.push(field);
        } else if (fieldInput != "all") {
          const index = this.errors.indexOf(field);
          index >= 0 && this.errors.splice(index, 1);
        }
      });
      this.$forceUpdate();
    },
    showDatepicker() {},
    addRoom(productObject = null) {
      let structure = {};
      if (productObject) {
        Object.keys(this.sampleStructure.room).forEach((prodKey) => {
          if (prodKey in productObject && productObject[prodKey]) {
            structure[prodKey] = productObject[prodKey];
          } else {
            structure[prodKey] = this.sampleStructure.room[prodKey];
          }
        });
      } else {
        structure = this.sampleStructure.room;
      }
      this.payload.products.push(Object.assign({}, structure));
      let newIndex = this.payload.products.length - 1;

      if (!productObject) {
        let roomCode =
          typeof this.config.config.rooms_default === "string"
            ? this.config.config?.rooms_show &&
              this.config.config?.rooms_default
            : (this.config.config?.rooms_show &&
                this.availableRooms.find(
                  (room) => room.id === this.config.config.rooms_default
                )?.external_code) ||
              "";
        this.selectRoom(newIndex, roomCode);
        this.payload.products[newIndex].mealplan_code =
          (this.config.config?.mealplans_show &&
            this.config.config?.mealplans_default) ||
          (this.config.config?.mealplans?.length === 1 &&
            this.config.config.mealplans?.[0]) ||
          "";
        let defaultOfferCode =
          (this.config.config?.offers_show &&
            this.config.config?.offers_default) ||
          "";
        if (typeof defaultOfferCode === "number") {
          defaultOfferCode =
            this.availableOffers.find((offer) => offer.id === defaultOfferCode)
              ?.external_code || "";
        }
        this.payload.products[newIndex].rateplan_code = defaultOfferCode;
      }
      return newIndex;
    },
    removeRoom(index) {
      this.payload.products.splice(index, 1);

      this.data.adults = this.data.adults.filter((item) => item.room != index);
      this.data.adults
        .filter((item) => item.room > index)
        .forEach((item) => {
          item.room--;
        });

      this.data.children = this.data.children.filter(
        (item) => item.room != index
      );
      this.data.children
        .filter((item) => item.room > index)
        .forEach((item) => {
          item.room--;
        });
    },
    getAgeCode(value) {
      let age_code = null;
      if (value <= 2) {
        age_code = "infant";
      } else if (value <= 12) {
        age_code = "child";
      } else if (value <= 17) {
        age_code = "teenager";
      }
      return age_code;
    },
    setChildAge(index, puIndex, value, internalRoomIndex = null) {
      let age_code = this.getAgeCode(value);
      let indexOfObjectToChange = this.data.children.findIndex(
        (item) =>
          item.room == index && item.internalRoomIndex == internalRoomIndex
      );
      // console.log(indexOfObjectToChange)
      let ageObj = Object.assign({}, this.data.children[indexOfObjectToChange]);
      ageObj.age_code = age_code;
      ageObj.age = parseInt(value);
      this.$set(this.data.children, indexOfObjectToChange, ageObj);
      // console.log(this.data.children.filter(p => p.room == index))
    },
    increment(index, type, age = 0) {
      if (type == "adults") {
        let ageObject = {
          age_code: "adult",
          room: index,
          age: 18
        };
        this.data.adults.push(ageObject);
      } else {
        let ageObject = {
          age_code: this.getAgeCode(age),
          room: index,
          age: age
        };
        ageObject.internalRoomIndex = this.data.children.filter(
          (item) => item.room == index
        ).length;
        this.data.children.push(ageObject);
      }
    },
    decrement(index, type) {
      const participants = this.data?.[type]?.filter(
        (item) => item.room == index
      );
      if (participants?.length) {
        const toBeRemovedIndex = this.data?.[type]?.findLastIndex(
          (item) => item.room == index
        );
        if (toBeRemovedIndex > -1) {
          this.data?.[type]?.splice(toBeRemovedIndex, 1);
        }
      }
    },
    setDates(field, date) {
      this.payload[field] = this.$moment(date).format("YYYY-MM-DD");
      let roomIndex = 0;
      while (roomIndex < this.payload.products?.length || 0) {
        this.setRatePlan(roomIndex);
        roomIndex++;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import "~vue-tel-input/dist/vue-tel-input.css";

label {
  cursor: pointer;
}

.widget-body {
  text-align: left;
}

.picture-wrapper {
  cursor: pointer;
  background-size: cover;
  background-position: center center;
  position: relative;
  max-height: 600px;
}

.room-wrapper + .room-wrapper {
  border-top: 1px solid #e6e6e6;
  margin-top: 20px;
  padding-top: 10px;
}

.room-wrapper .image-count {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 40px;
  min-width: 50px;
  max-width: 60px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  left: unset;
  border-top-left-radius: 4px;
  top: unset;
  padding: 10px;
  text-align: center;
  box-shadow: 0 0 50px #dadada !important;
}

* {
  font-family: var(--general_font_family);
  color: var(--general_font_color);
}

/*.custom-check {*/
/*    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");*/
/*    background-position: center right;*/
/*    background-repeat: no-repeat;*/
/*    background-size: 10px;*/
/*    padding-right: 20px;*/
/*}*/

.modal {
  background: rgba(0, 0, 0, 0.4);
}

.grecaptcha-badge {
  display: none !important;
}

.vhd__datepicker__dummy-wrapper {
  border: none !important;
}
</style>
<style lang="scss"></style>
