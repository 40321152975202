export default {
  datepicker: {
    night: "Notte",
    nights: "Notti",
    week: "Settimana",
    weeks: "Settimane",
    "day-names": ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
    "check-in": "Arrivo",
    "check-out": "Partenza",
    "month-names": [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre"
    ],
    tooltip: {
      halfDayCheckIn: "Check-in Disponibile",
      halfDayCheckOut: "Check-out Disponibile",
      saturdayToSaturday: "Solo da Sabato a Sabato",
      sundayToSunday: "Solo da Domenica a Domenica",
      minimumRequiredPeriod: "%{minNightInPeriod} %{night} minimo."
    },
    format: "DD/MM/YYYY",
    format_placehoder: "GG/MM/AAAA"
  },
  form: {
    check_in_out: "Data {check_in}/{check_out}",
    age_child_x: "Età bambino {x}",
    persons_x: "{min}-{max} Persone",
    mealplan: "Trattamento",
    board: {
      any: "Qualsiasi trattamento"
    },
    adult_pl: "Adulto | Adulti",
    child_pl: "Bambino | Bambini",
    children: {
      age_x: "<1|{count} Anno |{count} Anni"
    },
    offer: {
      any: "Qualsiasi offerta",
      offer_pl: "Offerta | Offerte"
    },
    room: {
      any: "Qualsiasi camera",
      room_pl: "Camera | Camere",
      add: "Aggiungi camera",
      remove: "Rimuovi camera"
    },
    gender: {
      label: "Titolo",
      m: "Sig.",
      f: "Sig.ra"
    },
    gender_prefix: {
      label: "Titolo",
      mr: "Signor",
      ms: "Signora",
      family: "Famiglia",
      doctor: "Dottor",
      doctor_ms: "Dottoressa",
      ms_mr: "Sig. e Sig.ra",
      company: "Azienda"
    },
    companyname: "Nome dell'azienda",
    firstname: "Nome",
    lastname: "Cognome",
    email: "E-Mail",
    phone: "Telefono",
    street: "Indirizzo",
    zip: "CAP",
    city: "Località",
    country_iso: "Paese",
    note: "Note",
    error: {
      hint_missing: "Si prega di compilare '{field}'",
      hint_invalid: "Si prega di compilare correttamente '{field}'",
      consent_mandatory: "Si prega di compilare il campo obligatorio"
    },
    submit: "Invia richiesta",
    subscribe_now: "ISCRIVITI ORA",
    messages: {
      request_sent: "La richiesta è stata inviata con successo",
      error: "Si è verificato un errore"
    },
    consent_default_title: "Consenso al marketing"
  },
  success_messages: {
    newsletter_request: {
      headline: "Iscrizione alla newsletter avvenuta con successo!",
      context_1:
        "La ringraziamo per l'iscrizione alla nostra newsletter. Riceverà un'e-mail a breve. Per favore, confermi la sua registrazione cliccando sul link."
    },
    booking_request: {
      headline: "Richiesta inviata con successo!",
      context_1: "La ringraziamo per la sua richiesta e per l'interesse.",
      context_2:
        "Riceverà a breve la nostra offerta individuale per il periodo desiderato."
    }
  },
  default: {
    error_message:
      "Si è verificato un errore imprevisto.\n La preghiamo di riprovare più tardi."
  }
};
